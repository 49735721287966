export default {
  title: 'Plugins',

  wooreceiver: {
    title: 'WooCommerce connector',

    shopStatus: {
      disActive: 'Disabled',
      checkInformation: 'Check information',
      stopped: 'Stopped',
      active: 'Active',
      receivingInformation: 'Exchanging information'
    },

    labels: {
      name: 'Store Name',
      url: 'Site Address (URL)',
      key: 'Consumer key',
      secret: 'Consumer secret',
      receive: 'Receive',
      userInfo: 'User Information',
      productInfo: 'Product Information',
      orderInfo: 'Order Information',
      delete: 'Delete Store',
      product: 'Product',
      setProductPending: 'Change the status of the item to pending review after changing the inventory status',
      barcodeContainsNumber: 'Barcode contain number only',
      sale: 'Sale',
      draftOrderEnabled: 'Draft order enabled',
      inventory: 'Inventory',
      storeroomsPriority: 'Warehouses priority for sale'
    },

    description: {
      notExist: 'No stores registered!'
    },

    notifications: {
      receivingInformation: 'The store connector is exchanging information. Please wait ...',
      noChange: 'No changes have been made',
      requiredFields: 'Required Fields are Missing',
      requestSend: 'Sending request succeed',
      serviceNotActive: 'This service is not active',

      insert: {
        success: 'Creating the store succeed',
        error: 'Creating the store failed'
      },

      edit: {
        success: 'Updating the store succeed',
        error: 'Updating the store failed'
      },

      editStoreroom: {
        success: 'Updating the storeroom setting succeed',
        error: 'Updating the storeroom setting failed'
      },

      delete: {
        success: 'Deleting the store succeed',
        error: 'Deleting the store failed'
      }
    },

    confirmations: {
      delete: {
        title: 'Store Delete Confirmation',
        body: 'Are you sure you want to delete {name} temporarily?'
      }
    },

    profile: {
      title: 'Site Profile',
      dynamicTitle: 'Store {name}',

      importantData: {
        url: 'URL',
        token: 'Token',
        status: 'Status'
      },

      actions: {
        category: 'Category Controller',
        categoryMapper: 'Category Mapper',
        attributes: 'Attribute Controller',
        products: 'Product Controller',
        inventory: 'Inventory Controller',
        price: 'Price Controller',
        paymentGateways: 'Payment Method Controller',
        orders: 'Invoice Controller',
        users: 'Customer Controller',
        setting: 'Settings'
      },

      notifications: {
        attributeErrorNotSolved: 'Attribute information errors not fixed'
      }
    },

    insert: {
      title: 'Create New Store'
    },

    edit: {
      title: 'Edit Store',
      dynamicTitle: 'Edit Store {name}'
    },

    attributes: {
      list: {
        title: 'Attribute Control',

        table: {
          header: {
            rowNumber: 'Row',
            name: 'Attribute name',
            values: 'Attribute values',
            send: 'Send'
          }
        },

        confirmations: {
          get: {
            title: 'Are you sure you want to get the attributes list?'
          },
          sync: {
            title: 'Are you sure you want to transfer the attributes list to the useral?'
          }
        },

        notifications: {
          handleGet: {
            error: 'All attributes received'
          },
          handleSync: {
            error: 'All attributes has been transferred to the useral'
          },
          receive: {
            success: 'Sending the request succeed',
            error: 'Sending the request failed'
          },
          sync: {
            success: 'Sending the request succeed',
            error: 'Sending the request failed'
          },
          send: {
            success: 'Sending the request succeed',
            error: 'Sending the request failed'
          }
        }
      },

      insert: {
        title: 'Create Attribute'
      },

      edit: {
        title: 'Update Attribute'
      },

      profile: {
        title: 'Attributes',
        dynamicTitle: 'Attribute {name}'
      },

      logs: {
        title: 'Attribute Logs',
        dynamicTitle: 'Attribute Logs {name}',

        labels: {
          values: 'Attribute values',
          destinations: 'Log Description'
        },

        table: {
          header: {
            rowNumber: 'Row',
            description: 'Description',
            date: 'Date'
          }
        }
      }
    },

    categories: {
      title: 'Category Controller',

      labels: {
        withoutCategory: 'without category'
      },

      notifications: {
        notCategorySelected: 'No categories selected',

        setMainCategories: {
          success: 'Registering the main categories succeed',
          error: 'Registering the main categories failed'
        },

        setSetting: {
          success: 'Registering the category mapper settings succeed',
          error: 'Registering the category mapper settings failed'
        }
      },

      categoryMapper: {
        title: 'Categories Mapper',

        table: {
          header: {
            rowNumber: 'Row',
            name: 'WooCommerce Category Title',
            mapTo: 'Useral Category Title'
          }
        }
      }
    },

    products: {
      title: 'Product Controller',

      types: {
        all: 'All',
        simple: 'Simple',
        variant: 'Variable',
        service: 'Service'
      },

      dataCategory: {
        generalInfo: 'General Information',
        attributes: 'Attributes',
        inventory: 'Inventory Management',
        variants: 'Variants'
      },

      productType: {
        label: 'Product type',
        simple: 'Simple',
        variant: 'Variable'
      },

      labels: {
        useral: 'Useral',
        woocommerce: 'Woocommerce',
        active: 'Active',
        disActive: 'Disable',
        have: 'Yes',
        notHave: 'No',
        from: 'From',
        to: 'To',
        withoutPrice: 'No Price',
        code: 'Code',
        barcode: 'Barcode',
        description: 'Description',
        inventory: 'Inventory',
        chooseAttribute: 'Select attribute',
        chooseCategory: 'Select category',
        withoutCategory: 'without category',
        add: 'Create Product',
        delete: 'Delete Product',

        product: {
          name: 'Product name',
          productShopName: 'Product name in site',
          category: 'Category',
          price: 'Product price'
        },
        service: {
          name: 'Service Name',
          category: 'Service Category',
          price: 'Service price'
        }
      },

      table: {
        header: {
          delete: 'Delete',
          price: 'Price',
          barcode: 'Barcode',
          stock: 'Inventory'
        }
      },

      descriptions: {
        title: 'Description',

        woocommerceDescription: 'Product information is recorded directly in your store under review status.',
        attribute: 'You can select the Attributes you want and add it to the product Attributes list. If the product is a variable, you can select <b> one </b> of the Attributes as the variable attribute of the product ',
        inventory: 'On this page you can enter the entire product inventory. If the number of inventories is zero, the product will be considered non-existent. ',
        noAttributeChooseForVariables: 'No attribute selected as variable'
      },

      confirmations: {
        sync: {
          title: '',
          body: 'Are you sure you want to transfer the website products to the useral?'
        },
        get: {
          title: '',
          body: 'Are you sure you want to receive the product list?'
        },
        delete: {
          title: 'Confirmation',
          body: 'Are you sure you want to delete this item? If confirmed, product information will also be removed from your store! '
        }
      },

      validators: {
        imageSize: 'The selected file size is too large',
        fileFormat: 'The selected file format is not allowed'
      },

      notifications: {
        requestSend: 'Request sent. please wait...',
        technicalProblem: 'There are products with technical problems in the list, please try to fix the problem',
        useralTransfer: {
          error: 'All products must be transferred to the useral first'
        },
        send: {
          success: 'Request sent successfully',
          error: 'Send request requested error',
          notTransfer: 'The item in question has not been transferred to the useral'
        },
        choose: {
          dynamicSuccess: 'Product {name} has been selected'
        },
        get: {
          success: 'Request successfully submitted',
          error: 'Send request requested error',
          getAll: 'Store products received'
        },
        publish: {
          success: 'Commodity information successfully verified',
          error: 'Error verifying product information'
        },
        transfer: {
          success: 'Request sent successfully',
          error: 'Send request requested error',
          transferAll: 'All products have been transferred to useral'
        },

        insert: {
          success: 'Product information was successfully registered in the store',
          error: 'Registration of product information encountered an error'
        },

        edit: {
          success: 'Product information was successfully updated in the store',
          error: 'Product information update failed'
        },

        delete: {
          success: 'Product information successfully deleted',
          error: 'Deleting product information was accompanied by an error'
        },

        visible: {
          success: 'Product set visible successfully',
          error: 'Product set visible has error'
        },

        invisible: {
          success: 'Product set invisible successfully',
          error: 'Product set invisible has error'
        },

        parseError: {
          wrongValues: 'The information entered is incorrect'
        }
      },

      list: {
        title: 'Control of site products',
        dynamicTitle: 'Product control {name}',
        invisibleDynamicTitle: 'Invisible product control {name}',

        status: {
          all: 'All',
          notConnect: 'No connection',
          draft: 'Draft',
          waiting: 'Pending',
          published: 'Published',
          personal: 'Private'
        },

        inStockTypes: {
          available: 'Available in stock',
          notAvailable: 'Unavailable'
        },

        table: {
          header: {
            rowNumber: 'Row',
            image: 'Image',
            code: 'ID',
            type: 'Type',
            creator: 'Creator',
            name: 'Title',
            shopName: 'Ecommerce Name',
            category: 'Category',
            technicalProblem: 'Technical problem',
            status: 'Release status',
            backOrdered: 'Pre-ordered status',
            inStock: 'Inventory Status',
            manageStock: 'Inventory Management',
            receivedDate: 'Received Date',
            transferredDate: 'Transfer date',
            send: 'Send'
          }
        },

        variantTable: {
          header: {
            rowNumber: 'Row',
            receivedId: 'Variable ID',
            variant: 'Variable',
            stock: 'Inventory',
            barcode: 'Barcode',
            price: 'Price',
            transferStatus: 'Transfer status',
            published: 'Release Status',
            inStock: 'Inventory Status'
          }
        }
      },

      profile: {
        title: 'Product profile',

        importantData: {
          price: 'Price',
          salePrice: 'Sale price',
          type: 'type'
        },

        actions: {
          attributes: 'Attributes',
          variant: 'Variables',
          logs: 'Logs'
        }
      },

      insert: {
        title: 'Add New Product',

        notifications: {
          insertSoon: 'Product information will be stored in WooController for a while'
        }
      },

      edit: {
        title: 'Edit Product',

        labels: {
          salePrice: 'Sale price',
          manageStock: 'Inventory Management',
          backOrdered: 'Pre-ordered',
          attributeInsert: 'Create new variable'
        },

        description: {
          editVariant: 'You can edit information about each variable in your store through this page.',
          error: 'Operation encountered error',
          noLog: 'There are no logs for this product'
        },

        notifications: {
          variantExist: 'There is a variable with this feature',
          salePrice: 'The sale price could not be higher than the original price',
          dynamicSalePrice: 'Variable sale price with barcode {barcode} could not be higher than the original price'
        }
      },

      logs: {
        title: 'Product Logs',
        dynamicTitle: 'Logs {name}',

        descriptions: 'log description',

        table: {
          header: {
            rowNumber: 'Row',
            description: 'Description',
            date: 'Date'
          }
        }
      },

      attributes: {
        title: 'Product attributes',
        dynamicTitle: 'Attributes {name}',

        table: {
          header: {
            rowNumber: 'Row',
            key: 'Attribute title',
            value: 'Attribute value'
          }
        }
      },

      variant: {
        title: 'Product variants',
        dynamicTitle: '{name} variables',

        table: {
          header: {
            logs: 'Logs',
            transferredDate: 'Transfer date',
            receivedDate: 'Received Date',
            creator: 'Creator',
            salePrice: 'Sale price',
            price: 'Price',
            barcode: 'Barcode',
            stock: 'Inventory'
          }
        },

        edit: {
          labels: {
            barcode: 'Barcode',
            price: 'Price',
            salePrice: 'Sale price',
            stock: 'Inventory',
            manageStock: 'Inventory Management',
            backOrdered: 'Pre-ordered',
            delete: 'Delete product'
          },

          confirmations: {
            delete: {
              title: 'Confirmation',
              body: 'Are you sure you want to delete this variable? If confirmed, product information will also be removed from your store! '
            }
          }
        },

        logs: {
          dynamicTitle: 'Variable logs with barcode {barcode}'
        },

        notifications: {
          delete: {
            success: 'Variable information successfully deleted',
            error: 'Deleting variable information was associated with an error'
          }
        }
      }
    },

    inventory: {
      title: 'Inventory control',
      dynamicTitle: 'Inventory control {name}',

      labels: {
        notAvailable: 'Unavailable'
      },

      table: {
        header: {
          rowNumber: 'Row',
          image: 'Image',
          code: 'ID',
          barcode: 'Barcode',
          name: 'Title',
          variant: 'Variable',
          woocommerceStock: 'Site Inventory',
          useralStock: 'Useral',
          updateDate: 'Site update date',
          sync: 'Send'
        }
      },

      confirmations: {
        syncUseral: {
          title: 'Message',
          body: 'Are you sure you want to transfer the inventory to the user?'
        },

        syncWoocommerce: {
          title: 'Message',
          body: 'Are you sure you want to send inventory to WooCommerce?'
        },

        receive: {
          title: 'Message',
          body: 'Are you sure you want to receive inventory from WooCommerce?'
        }
      },

      notifications: {
        transferAll: 'All products must first be transferred to the useral',
        sync: {
          success: 'Updating product Inventory on the site succeed',
          error: 'Updating product inventory on site failed'
        },

        requestSend: {
          success: 'Request sent',
          error: 'Send request requested error'
        }
      }
    },

    prices: {
      title: 'Price control',
      dynamicTitle: 'price control {name}',

      table: {
        header: {
          rowNumber: 'Row',
          image: 'Photo',
          code: 'ID',
          barcode: 'Barcode',
          name: 'Title',
          variant: 'Variable',
          woocommercePrice: 'Site Price',
          useralPrice: 'Useral price',
          woocommerceSalePrice: 'Site sale price',
          useralSalePrice: 'Useral sale price',
          updateDate: 'Site update date',
          update: 'Update',
          sync: 'Send'
        }
      },

      confirmations: {
        syncUseral: {
          title: 'Message',
          body: 'Are you sure you want to transfer the product price to the useral?'
        },

        syncWoocommerce: {
          title: 'Message',
          body: 'Are you sure you want to send product price to WooCommerce?'
        },

        receive: {
          title: 'Message',
          body: 'Are you sure you want to receive product price from WooCommerce?'
        }
      },

      notifications: {
        transferToUseral: 'All products must first be transferred to the useral',
        notTransfer: 'Product has not been moved to useral',
        inventoryCorrect: 'Inventory of this product on the site is correct',

        requestSend: {
          success: 'Request sent',
          error: 'Send request requested error'
        },

        edit: {
          success: 'Updating product inventory on the site succeed',
          error: 'Updating product inventory on site failed'
        }
      }
    },

    paymentGateways: {
      title: 'Control of payment methods',
      dynamicTitle: 'Control of payment methods {name}',

      statusTypes: {
        all: 'All',
        notConnect: 'No connection',
        active: 'Active',
        disActive: 'Disabled'
      },

      table: {
        header: {
          rowNumber: 'Row',
          title: 'Portal Title',
          status: 'Status',
          receivedDate: 'Received Date',
          transferredDate: 'Transfer date',
          send: 'Send'
        }
      },

      confirmations: {
        get: {
          title: '',
          body: 'Are you sure you want to receive the product list?'
        }
      },

      notifications: {
        notTransfer: 'The payment method has not been transferred to the useral',
        receivedProducts: 'Store products received',

        requestSend: {
          success: 'Request sent successfully',
          error: 'Send request has error'
        },

        requestSave: {
          success: 'Request successfully submitted'
        }
      },

      sync: {
        title: '',
        dynamicTitle: 'Move port "{name}" to useral',

        types: {
          internet: 'Internet portal',
          pos: 'Card reader'
        },

        labels: {
          bank: 'Clearing bank',
          type: 'Port type',
          endTime: 'Ports working day end time',
          descriptions: 'Description:'
        },

        notifications: {
          parseError: {
            bank: 'Settlement bank not selected',
            type: 'Port type not selected',
            endTime: 'Ports business day end time is not entered correctly'
          }
        }
      }
    },

    orders: {
      title: 'Invoice Control',

      invoiceProcesses: {
        notConnect: 'No connection',
        paymentWaiting: 'Waiting for payment',
        doing: 'In progress',
        reviewWaiting: 'Waiting for review',
        completed: 'Completed',
        cancel: 'Canceled',
        rejected: 'Rejected',
        unSuccess: 'Unsuccessful',
        deleted: 'Deleted'
      },

      list: {
        title: 'List of store orders',
        dynamicTitle: 'Store order list {name}',

        technicalProblemTypes: {
          all: 'All',
          have: 'Has',
          notHave: 'No'
        },

        statusTypes: {
          all: 'All',
          preInvoice: 'Pre-purchase invoice',
          invoice: 'Invoice',
          storeReceipt: 'Pull in'
        },

        labels: {
          unknown: 'Unknown'
        },

        table: {
          header: {
            invoiceNumber: 'Invoice Number',
            receivedId: 'Order ID',
            status: 'Status',
            user: 'Account side',
            date: 'Date',
            finalPrice: 'Final amount',
            totalDiscount: 'Total Discount',
            technicalProblem: 'Technical problem',
            send: 'Send'
          }
        },

        notifications: {
          notTransfer: 'The invoice has not been transferred to the useral',
          activeRefundInvoiceExists: 'For this invoice, return invoice {invoices} are created. First, change the status of these invoices to draft | For this invoice, return invoices {invoices} are created. First, change the status of these invoices to draft',

          requestSend: {
            success: 'Request sent successfully',
            error: 'Send request has error'
          },

          delete: {
            success: 'Invoice successfully deleted',
            error: 'Invoice can\'t delete'
          },
        },

        confirmations: {
          delete: {
            title: 'Confirmation of deletion of sales invoice',
            body: 'If the sales invoice is deleted, all the documents and delivery note of the warehouse related to the invoice will be deleted. Are you sure? '
          }
        },
      },

      order: {
        title: 'Products order invoice',
        dynamicTitle: 'Order invoice number {number}',

        labels: {
          user: 'Account side',
          id: 'Invoice ID',
          date: 'Registration date',
          status: 'Status'
        },

        placeholders: {
          id: 'Automatic'
        },

        table: {
          header: {
            rowNumber: 'Row',
            code: 'Barcode',
            name: 'Product',
            variant: 'Variable',
            store: 'Store',
            count: 'Count',
            unitPrice: 'Unit amount',
            totalPrice: 'Total amount',
            discount: 'Discount',
            finalPrice: 'Final amount'
          }
        }
      },

      logs: {
        title: 'Commodity order invoice logs',
        dynamicTitle: 'Order invoice logs with number {number}',

        descriptions: 'Log description',

        table: {
          header: {
            rowNumber: 'Row',
            description: 'Description',
            date: 'Date'
          }
        }
      }
    },

    users: {
      title: 'Customer Control',
      dynamicTitle: 'Customer Control {name}',
      invisibleDynamicTitle: 'Invisible Customer Control {name}',

      technicalProblemTypes: {
        all: 'All',
        have: 'Has',
        notHave: 'No'
      },

      labels: {
        useral: 'useral',
        unknown: 'Unknown'
      },

      table: {
        header: {
          rowNumber: 'Row',
          avatar: 'Avatar',
          receivedId: 'Customer ID',
          fullName: 'Name and Surname',
          company: 'Company',
          phoneNumber: 'Mobile',
          technicalProblem: 'Technical problem',
          receivedDate: 'Received Date',
          transferredDate: 'Transfer date',
          send: 'Send'
        }
      },

      confirmations: {
        sync: {
          title: '',
          body: 'Are you sure you want to transfer website customer information to the useral?'
        },

        technicalError: {
          title: 'error',
          body: 'Some customers information has a technical problem, please fix it'
        },

        getUsers: {
          title: '',
          body: 'Are you sure you want to receive the customer list?'
        }
      },

      notifications: {
        syncUsers: 'All customer information received from the site',
        notTransfer: 'The requested client has not been moved to the useral',
        transferToUseral: 'All customer information has been transferred to the user',

        requestSend: {
          success: 'Request sent successfully',
          error: 'Send request has error'
        },

        visible: {
          success: 'User set visible successfully',
          error: 'User set visible has error'
        },

        invisible: {
          success: 'User set invisible successfully',
          error: 'User set invisible has error'
        },

        requestSave: {
          success: 'Request successfully submitted'
        }
      },

      logs: {
        title: 'User logs',
        dynamicTitle: '{name} logs',
        description: 'Log description',

        table: {
          header: {
            rowNumber: 'Row',
            description: 'Description',
            date: 'Date'
          }
        }
      },

      edit: {
        title: '',
        dynamicTitle: 'Edit {name}',

        dataCategory: {
          generalInfo: 'General Information'
        },

        labels: {
          name: 'First Name',
          family: 'Last Name',
          company: 'Company',
          phoneNumber: 'Mobile number',
          email: 'Email',
          delete: 'Delete customer',
          customerLogs: 'Customer Logs'
        },

        descriptions: {
          noLog: 'There is no log for this person'
        },

        confirmations: {
          delete: {
            title: 'Confirmation',
            body: 'Are you sure you want to delete this customers information? If approved, the customer information will also be removed from your website! '
          }
        },

        notifications: {
          wrongInputValues: 'The information entered is incorrect',

          requestSend: {
            success: 'Request sent',
            wait: 'Request sent. please wait...'
          },

          edit: {
            success: 'Personally updated information',
            error: 'Error updating personal information'
          },

          delete: {
            error: 'Deleting personal information was associated with an error'
          }
        }
      }
    },

    setting: {
      title: 'Connector Settings'
    }
  }
}
